import classNames from 'classnames';
import { useLanguage } from 'providers/BaseStoresProvider';

type Props = {
  className?: string;
};
export default function LanguageSwitch(props: Props) {
  const language = useLanguage();

  return (
    <div
      className={classNames(
        'text-2xl flex items-center gap-1',
        props.className
      )}
    >
      {language.availableLanguages.map((x, i) => (
        <div key={x.id} className='flex items-center gap-1'>
          <div
            className='cursor-pointer'
            onClick={() => language.setLanguage(x)}
          >
            {x.text}
          </div>

          {i !== language.availableLanguages.length - 1 && <div>/</div>}
        </div>
      ))}
    </div>
  );
}
