import man1 from 'assets/images/composite/man1.png';
import man2 from 'assets/images/composite/man2.png';
import man3 from 'assets/images/composite/man3.png';
import man4 from 'assets/images/composite/man4.png';
import manleft from 'assets/images/composite/manleft.png';
import manright from 'assets/images/composite/manright.png';
import arrow from 'assets/images/composite/arrow.png';
import plashkaleft from 'assets/images/composite/plashka1.png';
import plaskaright from 'assets/images/composite/plashka2.png';
import Container from 'components/core/container';
import { useIntl } from 'react-intl';

type PictureProps = {};

export default function Picture(props: PictureProps) {
  const intl = useIntl();
  return (
    <div className='bg-black'>
      <Container className='flex flex-col items-center relative'>
        <div className='pt-16 mb-14 px-6 md:px-12 relative'>
          <img
            src={man1}
            alt=''
            className='w-24 block absolute right-0 top-0 sm:right-0 sm:top-0'
            loading='lazy'
          ></img>
          <div className='w-full max-w-3xl flex items-center rounded-full bg-white outline outline-offset-2 outline-2 outline-[#15803d]'>
            <p className='text-black text-center px-12 py-6 font-bold text-2xl w-full'>
              {intl.formatMessage({
                id: 'compositepic.part1.projects_growth',
                defaultMessage:
                  'Растет количество проектов и разработчиков в web3',
              })}
            </p>
          </div>
        </div>

        <div className='flex flex-col lg:flex-row items-center justify-center gap-44 lg:gap-12 px-2 pb-1 xl:pb-36 relative'>
          <div className='relative'>
            <img
              src={man2}
              alt=''
              className='w-28 absolute top-[100%] right-0 mb-4 sm:top-[80%] sm:right-[0%] lg:right-[0%] lg:w-24'
              loading='lazy'
            ></img>
            <div className='w-full max-w-sm flex items-center rounded-full bg-white outline outline-offset-2 outline-2 outline-[#cc4933] min-h-[128px]'>
              <p className='text-black text-center px-12 py-6 font-bold text-2xl w-full lg:p-2 lg:text-xl'>
                {intl.formatMessage({
                  id: 'compositepic.part2.growing_amount',
                  defaultMessage: 'Растет объем информации для аналитики',
                })}
              </p>
            </div>
          </div>

          <div className='relative'>
            <img
              src={man3}
              alt=''
              className='w-32 absolute top-[100%] right-0 sm:top-[80%] sm:right-[3%] lg:right-[0%] lg:w-24'
              loading='lazy'
            ></img>
            <div className='w-full max-w-sm flex items-center rounded-full bg-white outline outline-offset-2 outline-2 outline-[#cc4933] min-h-[128px]'>
              <p className='text-black text-center px-12 py-6 font-bold text-2xl w-full lg:p-2 lg:text-xl'>
                {intl.formatMessage({
                  id: 'compositepic.part3.harder_tokeep_attention',
                  defaultMessage:
                    'Проектам все труднее удержать на себе внимание',
                })}
              </p>
            </div>
          </div>

          <div className='relative'>
            <img
              src={man4}
              alt=''
              className='w-32 absolute top-[100%] right-0 sm:top-[80%] sm:right-[4%] lg:right-[0%] lg:w-24'
              loading='lazy'
            ></img>
            <div className='w-full max-w-sm flex items-center rounded-full bg-white outline outline-offset-2 outline-2 outline-[#cc4933] min-h-[128px]'>
              <p className='text-black text-center px-6 py-6 font-bold text-2xl w-full lg:p-2 lg:text-xl'>
                {intl.formatMessage({
                  id: 'compositepic.part4.worthy_project',
                  defaultMessage:
                    'Среди всего разнообразия становится сложнее найти достойный проект',
                })}
              </p>
            </div>
          </div>
        </div>

        <div className='flex flex-col lg:flex-col xl:flex-row items-center justify-center gap-2 lg:gap-8 xl:gap-0 font-bold pt-40 xl:py-16'>
          <img
            src={manleft}
            alt=''
            className='w-52 bottom-0 left-0 lg:absolute xl:bottom-0'
            loading='lazy'
          ></img>

          <div className='relative shrink-0'>
            <img
              src={plashkaleft}
              className='w-[460px] h-[140px] md:h-[120px] md:w-[550px] xl:w-[400px]'
              alt=''
              loading='lazy'
            />
            <div className='absolute left-[5%] right-[5%] top-0 bottom-[30%] flex items-center justify-center text-center text:md xl:text-sm'>
              <span>
                {intl.formatMessage({
                  id: 'compositepic.part5.airdrops',
                  defaultMessage:
                    'Проекты раздают аирдропы и разыгрывают вайтлисты',
                })}
              </span>
            </div>
          </div>
          <img
            src={arrow}
            alt=''
            className='w-24 m-auto rotate-90 xl:rotate-0'
            loading='lazy'
          ></img>
          <div className='relative shrink-0'>
            <img
              src={plaskaright}
              alt=''
              className='w-[460px] h-[160px] md:h-[130px] md:w-[550px] xl:w-[400px] xl:h-[130px]'
              loading='lazy'
            />
            <div className='absolute left-[5%] right-[5%] top-0 bottom-[20%] flex items-center text-center text:md xl:text-sm'>
              <span>
                {intl.formatMessage({
                  id: 'compositepic.part6.winning_competitions',
                  defaultMessage:
                    'Для победы в конкурсах может не хватить навыков для создания качественного контента',
                })}
              </span>
            </div>
          </div>

          <img
            src={manright}
            alt=''
            className='w-52 right-0 bottom-0 lg:absolute'
            loading='lazy'
          ></img>
        </div>
      </Container>
    </div>
  );
}
