import { useIntl } from 'react-intl';

type ProblemsProps = {};

export default function Problems(props: ProblemsProps) {
  const intl = useIntl();
  return (
    <div>
      <div>
        <p className='bg-gradientLight text-4xl text-center py-5 px-2'>
          {intl.formatMessage({
            id: 'problems.help',
            defaultMessage:
              'Эти и другие проблемы поможет решить команда Delovoy DAO!',
          })}
        </p>
      </div>
    </div>
  );
}
