import Dao from './dao';
import Picture from './compositepic';
import Problem from './problem';
import Problems from './problems';
import Projects from './projects';
import Roadmap from './roadmap';
import Team from './team';
import Welcome from './welcome';

export default function Body() {
  return (
    <>
      <Welcome />
      <Problem />
      <Picture />
      <Problems />
      <Dao />
      <Projects />
      <Roadmap />
      <Team />
    </>
  );
}
