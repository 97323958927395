import { FormattedMessage, useIntl } from 'react-intl';

type ProblemProps = {};

export default function Problem(props: ProblemProps) {
  const intl = useIntl();
  return (
    <div>
      <h2
        id='problem'
        className='bg-gradientLight text-5xl text-center py-3 px-2 scroll-mt-[64px]'
      >
        {intl.formatMessage({
          id: 'problem.tasks',
          defaultMessage: 'Какие задачи мы решаем?',
        })}
      </h2>
      <div className='text-center py-3 px-2 max-w-[1200px] mx-auto'>
        <p className='text-2xl'>
          <FormattedMessage
            id='problem.part1.world_web3'
            defaultMessage='Строящийся стремительными темпами web3 мир - отличноe место как для заработка, так и для {destruction} совместного времяпрепровождения с близкими по духу людьми.'
            values={{
              destruction: (
                <span className='line-through'>
                  {intl.formatMessage({
                    id: 'problem.part1.world_web3.destruction',
                    defaultMessage: 'разорения',
                  })}
                </span>
              ),
            }}
          />
        </p>

        <p className='py-4 text-4xl'>
          {intl.formatMessage(
            {
              id: 'problem.part2.not_right',
              defaultMessage: 'Но не всё здесь так радужно {sadFace}',
            },
            { sadFace: ':(' }
          )}
        </p>

        <p className='text-2xl'>
          {intl.formatMessage({
            id: 'problem.part3.transform_into',
            defaultMessage:
              'Иногда то, что является плюсом, легко может трансформироваться в минус:',
          })}
        </p>
      </div>
    </div>
  );
}
