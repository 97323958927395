import { usePopperTooltip } from 'react-popper-tooltip';

import firstProject from 'assets/images/projects/1.jpg';
import secondProject from 'assets/images/projects/2.png';
import thirdProject from 'assets/images/projects/3.jpg';
import fourthProject from 'assets/images/projects/4.jpg';
import fifthProject from 'assets/images/projects/5.jpg';

import officeJpg from 'assets/images/office.jpg';
import officeWebp from 'assets/images/office.webp';
import Container from 'components/core/container';
import { useIntl } from 'react-intl';

type Project = {
  imageUrl: string;
  description?: string;
  description1?: string;
  examples?: string;
  project1Name?: string;
  project2Name?: string;
  project1Url: string;
  project2Url?: string;
};

type ProjectItemProps = {
  project: Project;
};

function ProjectItem(props: ProjectItemProps) {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    interactive: true,
    trigger: 'click',
    closeOnOutsideClick: true,
  });

  return (
    <li>
      <img
        src={props.project.imageUrl}
        alt=''
        ref={setTriggerRef}
        className='w-full h-auto aspect-square max-w-[200px] cursor-pointer'
        loading='lazy'
      />

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container max-w-[320px]',
          })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />

          <div className='flex flex-col gap-1'>
            <div>{props.project.description}</div>
            <div>{props.project.description1}</div>
            <div>{props.project.examples}</div>
          </div>

          <a
            href={props.project.project1Url}
            target='_blank'
            rel='noreferrer'
            className='underline decoration-1 cursor-pointer'
          >
            {props.project.project1Name}
          </a>
          <a
            href={props.project.project2Url}
            target='_blank'
            rel='noreferrer'
            className='underline decoration-1 cursor-pointer'
          >
            {props.project.project2Name}
          </a>
        </div>
      )}
    </li>
  );
}

type ProjectsProps = {};

export default function Projects(props: ProjectsProps) {
  const intl = useIntl();
  const projects: Project[] = [
    {
      imageUrl: firstProject,

      description: intl.formatMessage({
        id: 'projects.part0.first_project',
        defaultMessage:
          'Команда Art of Dying от Delovoy DAO участвует в бета тестах этого P2E AI файтинга с самого старта. В первом и втором сезонах занимала вторые места. В третьем сезоне, в котором участвовали команды от таких крупных проектов как Azuki, Arbitrum, DigiDaigaku, illogics, наша команда была удостоена представлять сам проект AI Arena.',
      }),
      description1: intl.formatMessage({
        id: 'projects.part1.first_project',
        defaultMessage:
          'По итогам бета-сезонов, контрибьюторства и участия в контестах, самые активные члены команды получили WL на минт NFT проекта.',
      }),

      examples: intl.formatMessage({
        id: 'projects.part2.first_project',
        defaultMessage: 'Примеры контента:',
      }),

      project1Name: intl.formatMessage({
        id: 'projects.part1.first_project_url',
        defaultMessage: '- Аналитическая статья “Что такое AI Arena?”',
      }),
      project1Url:
        'https://twitter.com/onedayoneround/status/1591081814520913920',

      project2Name: intl.formatMessage({
        id: 'projects.part2.first_project_url',
        defaultMessage:
          '- Аналитическая статья “Является ли проект AI Arena уникальным?”',
      }),

      project2Url:
        'https://mirror.xyz/0x038F718A92583cf2298AE751cD3B15f5B5595e0e/KIyKL-cJES1sfIwk-tByGT5mx6IUOB6y8vpamNXAkqs',
    },
    {
      imageUrl: secondProject,
      description: intl.formatMessage({
        id: 'projects.part0.second_project',
        defaultMessage:
          'Некоторые участники DAO, являющиеся главными модераторами RU-сообщества проекта, поверили в THORChain еще в 2019 году. С тех пор они являются холдерами токена RUNE, который на своем пике дал 350х. ',
      }),
      examples: intl.formatMessage({
        id: 'projects.part2.second_project',
        defaultMessage: 'Примеры контента:',
      }),
      project1Name: intl.formatMessage({
        id: 'projects.part1.second_project_url',
        defaultMessage:
          '- Битва портфолио на площадке Getmoni c крупнейшим призовым фондом',
      }),
      project1Url: 'https://battles.getmoni.io/battle/69',

      project2Name: intl.formatMessage({
        id: 'projects.part2.2.second_project_url',
        defaultMessage:
          '- Вирусное видео, посвященное взлому THORChain, произошедшему в 2021 году',
      }),
      project2Url:
        'https://twitter.com/vladmanz/status/1421356002708971521?s=46&t=0iCyFR5vgyVV-I4q2Xe26Q',
    },
    {
      imageUrl: thirdProject,
      description: intl.formatMessage({
        id: 'projects.part0.third_project',
        defaultMessage:
          'Команда от Delovoy DAO - “Team Rubycon” регулярно участвует в амбассадорской программе “Mavia Elite” P2E игры Heroes of Mavia. По итогам нескольких сезонов наша команда неоднократно попадала на высшие строчки рейтинга, за что получала вайтлисты на земли данной игры.',
      }),
      examples: intl.formatMessage({
        id: 'projects.part2.third_project',
        defaultMessage: 'Примеры контента: ',
      }),
      project1Name: intl.formatMessage({
        id: 'projects.part1.third_project_url',
        defaultMessage: '- Mavia Elite Rap гимн',
      }),
      project1Url: 'https://www.youtube.com/watch?v=1aJDUooH4n8',

      project2Name: intl.formatMessage({
        id: 'projects.part2.third_project_url',
        defaultMessage: '- Пиксельный мультфильм “Помоги Doge найти Mira” ',
      }),
      project2Url: 'https://twitter.com/MaviaGame/status/1591219226647330819',
    },
    {
      imageUrl: fourthProject,
      description: intl.formatMessage({
        id: 'projects.part0.fourth_project',
        defaultMessage:
          'Русскоязычное сообщество Integral Protocol было создано Delovoy DAO и является стабильным и активным контрибьютором проекта уже более полутора лет, проводя различные конкурсы и гивы. Взамен наше DAO участвует в конкурсах от самого проекта и выигрывает практически все призы в них.',
      }),
      examples: intl.formatMessage({
        id: 'projects.part2.fourth_project',
        defaultMessage: 'Примеры контента:',
      }),
      project1Name: intl.formatMessage({
        id: 'projects.part1.fourth_project_url',
        defaultMessage:
          '- Meme contest “Integral SIZE = good, SIZE market = bad” with $ITGR token prizes- DeFi -',
      }),
      project1Url:
        'https://twitter.com/delovoyresearch/status/1533794416338554881',
      project2Name: intl.formatMessage({
        id: 'projects.part2.fourth_project_url',
        defaultMessage:
          '- Whale Squad NFT giveaway from our community to celebrate the launch of the collection',
      }),
      project2Url:
        'https://twitter.com/delovoyresearch/status/1508123556638601220',
    },
    {
      imageUrl: fifthProject,
      description: intl.formatMessage({
        id: 'projects.part0.fifth_project',
        defaultMessage:
          'LuchaTHORSquad от Delovoy DAO является единственным ру-сквадом данной игры. Участвуя в проводимых турнира наши игроки ',
      }),

      project1Name: intl.formatMessage({
        id: 'projects.part1.fifth_project_url',
        defaultMessage:
          'неоднократно становились победителями и получали достойные призы.',
      }),
      project1Url: 'https://lenster.xyz/posts/0xb8af-0x04',
    },
  ];

  return (
    <div className=''>
      <div className='bg-gray'>
        <Container>
          <picture>
            <source type='image/webp' srcSet={officeWebp} />
            <img
              src={officeJpg}
              alt=''
              width={2713}
              height={1188}
              loading='lazy'
            />
          </picture>
        </Container>
      </div>

      <h3
        id='projects'
        className='bg-gradientLight text-4xl text-center py-5 scroll-mt-[64px]'
      >
        {intl.formatMessage({
          id: 'projects.part6.examples',
          defaultMessage: 'Примеры работ',
        })}
      </h3>

      <Container>
        <ul className='p-4 flex flex-wrap justify-center gap-x-2 gap-y-10'>
          {projects.map((project, index) => (
            <ProjectItem key={index} project={project} />
          ))}
        </ul>

        <div className='flex items-center justify-end'>
          <p className='italic w-[50%] sm:w-[30%]'>
            {intl.formatMessage({
              id: 'projects.part7.official',
              defaultMessage: '*не являемся официальными ',
            })}
            <br />
            {intl.formatMessage({
              id: 'projects.part8.partners',
              defaultMessage: 'партнерами данных проектов',
            })}
          </p>
        </div>
      </Container>
    </div>
  );
}
