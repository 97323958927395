import logo from 'assets/images/logo.png';
import menu from 'assets/images/menu.png';
import telegram from 'assets/images/social/telegram.png';
import discord from 'assets/images/social/discord.png';
import twitter from 'assets/images/social/twitter.png';
import medium from 'assets/images/social/medium.png';
import { goToElement } from '../../utils/layout';
import {
  discordUrl,
  mediumUrl,
  telegramUrl,
  twitterUrl,
} from '../../utils/link';
import Container from 'components/core/container';
import { useIntl } from 'react-intl';
import LanguageSwitch from 'components/core/language-switch';

type Nav = {
  idIndex: string;
  buttonIndex: string;
  widthIndex: string;
};

type NavItemProps = {
  project: Nav;
};

function NavItem(props: NavItemProps) {
  return (
    <li className=''>
      <button
        onClick={() => goToElement(props.project.idIndex)}
        className={props.project.widthIndex}
      >
        {props.project.buttonIndex}
      </button>
    </li>
  );
}

type NavProps = { onOpenMenu: () => void };

export default function NavBar(props: NavProps) {
  const intl = useIntl();

  const projects: Nav[] = [
    {
      idIndex: 'problem',
      buttonIndex: intl.formatMessage({
        id: 'navbar.part1.problem',
        defaultMessage: 'Какие задачи мы решаем?',
      }),
      widthIndex: 'mx-2 w-44',
    },
    {
      idIndex: 'projects',
      buttonIndex: intl.formatMessage({
        id: 'navbar.part2.examples',
        defaultMessage: 'Примеры работ',
      }),
      widthIndex: 'mx-2 w-36',
    },
    {
      idIndex: 'roadmap',
      buttonIndex: intl.formatMessage({
        id: 'navbar.part3.roadmap',
        defaultMessage: 'Roadmap',
      }),
      widthIndex: 'mx-2 w-24',
    },
    {
      idIndex: 'team',
      buttonIndex: intl.formatMessage({
        id: 'navbar.part4.team',
        defaultMessage: 'Наша команда',
      }),
      widthIndex: 'mx-2 w-24',
    },
  ];

  return (
    <nav
      className='bg-black text-white flex sticky top-0 w-full z-10'
      id='delovoy'
    >
      <Container className='py-2 inline-flex items-center justify-between pr-2'>
        <div className='inline-flex'>
          <img src={logo} alt='logo' className='w-13 h-10' loading='lazy' />
          <div className='text-xl sm:text-2xl inline-flex items-center'>
            Delovoy DAO
          </div>
        </div>

        <div className=''>
          <ul className='lg:inline-flex lg:items-center lg:text-center lg:text-base drop-down hidden'>
            {projects.map((project, indexNav) => (
              <NavItem key={indexNav} project={project} />
            ))}
          </ul>
        </div>

        <div className='relative'>
          <LanguageSwitch className='hidden lg:inline-flex' />
        </div>

        <button
          className='lg:hidden w-12 flex right-0'
          onClick={props.onOpenMenu}
        >
          <img src={menu} alt='' loading='lazy' />
        </button>

        <div className='hidden lg:inline-flex justify-between '>
          <a
            href={twitterUrl}
            target='_blank'
            rel='noreferrer'
            className='w-8 h-8 mx-1'
          >
            <img src={twitter} alt='' loading='lazy' />
          </a>
          <a
            href={discordUrl}
            target='_blank'
            rel='noreferrer'
            className='w-8 h-8 mx-1'
          >
            <img src={discord} alt='' loading='lazy' />
          </a>
          <a
            href={telegramUrl}
            target='_blank'
            rel='noreferrer'
            className='w-8 h-8 mx-1'
          >
            <img src={telegram} alt='' loading='lazy' />
          </a>
          <a
            href={mediumUrl}
            target='_blank'
            rel='noreferrer'
            className='w-8 h-8 mx-1'
          >
            <img src={medium} alt='' loading='lazy' />
          </a>
        </div>
      </Container>
    </nav>
  );
}
