import twitter from 'assets/images/social/twitter.png';
import discord from 'assets/images/social/discord.png';
import telegram from 'assets/images/social/telegram.png';
import medium from 'assets/images/social/medium.png';
import { goToElement } from 'utils/layout';
import { discordUrl, mediumUrl, telegramUrl, twitterUrl } from 'utils/link';
import Container from 'components/core/container';
import LanguageSwitch from 'components/core/language-switch';

type FooterProps = {};

export default function Footer(props: FooterProps) {
  return (
    <div className='bg-black text-white flex'>
      <Container className='inline-flex items-center p-4 justify-between'>
        <div className='text-xl sm:text-2xl'>
          <button onClick={() => goToElement('delovoy')}>Delovoy DAO</button>
        </div>

        <div className='hidden lg:inline-flex py-2'>
          <LanguageSwitch className='inline-flex px-4 text-center' />

          <div className='lg:inline-flex hidden'>
            <a
              href={twitterUrl}
              target='_blank'
              rel='noreferrer'
              className='w-8 h-8 mx-1'
            >
              <img src={twitter} alt='' loading='lazy' />
            </a>
            <a
              href={discordUrl}
              target='_blank'
              rel='noreferrer'
              className='w-8 h-8 mx-1'
            >
              <img src={discord} alt='' loading='lazy' />
            </a>
            <a
              href={telegramUrl}
              target='_blank'
              rel='noreferrer'
              className='w-8 h-8 mx-1'
            >
              <img src={telegram} alt='' loading='lazy' />
            </a>
            <a
              href={mediumUrl}
              target='_blank'
              rel='noreferrer'
              className='w-8 h-8 mx-1'
            >
              <img src={medium} alt='' loading='lazy' />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}
