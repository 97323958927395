import classNames from 'classnames';
import telegram from 'assets/images/social/telegram.png';
import discord from 'assets/images/social/discord.png';
import twitter from 'assets/images/social/twitter.png';
import medium from 'assets/images/social/medium.png';
import {
  discordUrl,
  mediumUrl,
  telegramUrl,
  twitterUrl,
} from '../../utils/link';
import { goToElement } from 'utils/layout';
import { BsX } from 'react-icons/bs';
import LanguageSwitch from 'components/core/language-switch';
import { useIntl } from 'react-intl';

type Props = {
  open: boolean;
  onCloseMenu: () => void;
};

type Nav = {
  idIndex: string;
  buttonIndex: string;
  widthIndex: string;
};

type NavItemProps = {
  project: Nav;
  onClick: () => void;
};

function NavItem(props: NavItemProps) {
  return (
    <li className='py-2'>
      <button
        onClick={() => {
          goToElement(props.project.idIndex);
          props.onClick();
        }}
        className={props.project.widthIndex}
      >
        {props.project.buttonIndex}
      </button>
    </li>
  );
}

function MobileMenu(props: Props) {
  const intl = useIntl();

  const projects: Nav[] = [
    {
      idIndex: 'problem',
      buttonIndex: intl.formatMessage({
        id: 'navbar.part1.problem',
        defaultMessage: 'Какие задачи мы решаем?',
      }),
      widthIndex: 'my-2 w-full',
    },
    {
      idIndex: 'projects',
      buttonIndex: intl.formatMessage({
        id: 'navbar.part2.examples',
        defaultMessage: 'Примеры работ',
      }),
      widthIndex: 'my-2 w-full',
    },
    {
      idIndex: 'roadmap',
      buttonIndex: intl.formatMessage({
        id: 'navbar.part3.roadmap',
        defaultMessage: 'Roadmap',
      }),
      widthIndex: 'my-2 w-full',
    },
    {
      idIndex: 'team',
      buttonIndex: intl.formatMessage({
        id: 'navbar.part4.team',
        defaultMessage: 'Наша команда',
      }),
      widthIndex: 'my-2 w-full',
    },
  ];

  return (
    <div
      className={classNames(
        'fixed top-0 left-0 right-0 bottom-0 z-50',
        'bg-black text-white flex-col',
        'translate-x-full transition-transform duration-300',

        { 'translate-x-0': props.open === true }
      )}
    >
      <div className='flex items-center justify-end'>
        <button onClick={props.onCloseMenu} className='p-2'>
          <BsX className='text-5xl' />
        </button>
      </div>

      <div className='flex justify-center p-12'>
        <ul className='flex flex-col items-center text-2xl'>
          {projects.map((project, indexNav) => (
            <NavItem
              key={indexNav}
              project={project}
              onClick={props.onCloseMenu}
            />
          ))}
        </ul>
      </div>

      <div className='flex justify-center pt-6 pb-12'>
        <LanguageSwitch className='inline-flex' />
      </div>

      <div className='flex flex-row justify-around p-12'>
        <a
          href={twitterUrl}
          target='_blank'
          rel='noreferrer'
          className='w-16 h-16 mx-1'
        >
          <img src={twitter} alt='' loading='lazy' />
        </a>
        <a
          href={discordUrl}
          target='_blank'
          rel='noreferrer'
          className='w-16 h-16 mx-1'
        >
          <img src={discord} alt='' loading='lazy' />
        </a>
        <a
          href={telegramUrl}
          target='_blank'
          rel='noreferrer'
          className='w-16 h-16 mx-1'
        >
          <img src={telegram} alt='' loading='lazy' />
        </a>
        <a
          href={mediumUrl}
          target='_blank'
          rel='noreferrer'
          className='w-16 h-16 mx-1'
        >
          <img src={medium} alt='' loading='lazy' />
        </a>
      </div>
    </div>
  );
}

export default MobileMenu;
