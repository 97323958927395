import classNames from 'classnames';

type ContainerProps = {
  className?: string;
  children?: any;
};

const Container = ({ children, className }: ContainerProps) => {
  return (
    <div
      className={classNames('max-w-screen-desktop w-full mx-auto', className)}
    >
      {children}
    </div>
  );
};

export default Container;
