import manJpg from 'assets/images/man.jpg';
import manWebp from 'assets/images/man.webp';
import telegram from 'assets/images/social/telegram.png';
import Container from 'components/core/container';
import { useIntl } from 'react-intl';
import { telegramUrl } from 'utils/link';

type WelcomeProps = {};

export default function Welcome(props: WelcomeProps) {
  const intl = useIntl();
  return (
    <div className='bg-gradient'>
      <Container className='flex flex-col lg:flex-row'>
        <div className='flex flex-auto flex-col text-center font-medium text-2xl px-2'>
          <h1 className='text-center text-6xl py-12 font-medium pt-24'>
            {intl.formatMessage({
              id: 'welcome.part1.amount_projects',
              defaultMessage: 'Welcome to Delovoy DAO',
            })}
          </h1>
          <p className='font-semibold max-w-[1200px]'>
            {intl.formatMessage({
              id: 'welcome.part2.sincere_team',
              defaultMessage:
                'Душевный коллектив креативщиков, P2E-игроков, NFT-дегенов, мемоделов и других талантливых криптанов, готовых разорвать любую амбассадорку, выдать альфу или просто пофлексить в web3',
            })}
          </p>
          <p className='pt-5 font-semibold'>
            {intl.formatMessage({
              id: 'welcome.part3.new_people',
              defaultMessage: 'Всегда рады новым людям',
            })}
          </p>
          <p className='pt-5 font-semibold'>
            {intl.formatMessage({
              id: 'welcome.part4.become_a_part',
              defaultMessage: 'Стань частью Delovoy DAO',
            })}
          </p>
          <div className='w-20 mx-auto my-8'>
            <a href={telegramUrl} target='_blank' rel='noreferrer'>
              <img src={telegram} alt='' loading='lazy' />
            </a>
          </div>
        </div>

        <picture className='mx-auto lg:min-w-[460px] lg:max-w-[460px]'>
          <source type='image/webp' srcSet={manWebp} />
          <img
            src={manJpg}
            className='mx-auto lg:min-w-[460px] lg:max-w-[460px]'
            alt=''
            width={1968}
            height={2704}
            loading='lazy'
          />
        </picture>
      </Container>
    </div>
  );
}
