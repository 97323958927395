import delovoy from 'assets/images/delovoy_mem.png';
import discord from 'assets/images/social/discord.png';
import telegram from 'assets/images/social/telegram.png';
import Container from 'components/core/container';
import { useIntl } from 'react-intl';
import { discordUrl, telegramUrl } from 'utils/link';

type DaoProps = {};

export default function Dao(props: DaoProps) {
  const intl = useIntl();
  return (
    <div className='bg-black text-white'>
      <Container>
        <h2 className='text-5xl text-center m-8'>
          {intl.formatMessage({
            id: 'dao.part1.delovoy',
            defaultMessage: 'Delovoy DAO - это',
          })}
        </h2>
        <div className='sm:grid lg:grid-cols-2 sm:grid-cols-1'>
          <div className='block m-4'>
            <h3 className='text-4xl text-center'>
              {intl.formatMessage({
                id: 'dao.part2.0.for_cryptans',
                defaultMessage: 'Для криптанов:',
              })}
            </h3>
            <ul className='list-disc m-8 text-2xl'>
              <li className='m-4'>
                {intl.formatMessage({
                  id: 'dao.part2.1.receiving_bonuses',
                  defaultMessage:
                    'Получение бонусов (WL, ранние доступы) от проектов-партнеров',
                })}
              </li>
              <li className='m-5'>
                {intl.formatMessage({
                  id: 'dao.part2.2.analytics',
                  defaultMessage:
                    'Аналитика по новым перспективным блокчейн проектам/направлениям',
                })}
              </li>
              <li className='m-5'>
                {intl.formatMessage({
                  id: 'dao.part2.3.gives',
                  defaultMessage:
                    'Эксклюзивные гивы, раффлы для нашего коммьюнити',
                })}
              </li>
              <li className='m-5'>
                {intl.formatMessage({
                  id: 'dao.part2.4.join_forces',
                  defaultMessage:
                    'Объединение усилий для создания качественного контента',
                })}
              </li>
              <li className='m-5'>
                {intl.formatMessage({
                  id: 'dao.part2.5.guild',
                  defaultMessage:
                    'Гарантированное место в созданных силами нашего DAO гильдиях крупных P2E проектов',
                })}
              </li>
              <li className='m-5'>
                {intl.formatMessage({
                  id: 'dao.part2.6.community',
                  defaultMessage: 'Адекватное, душевное коммьюнити',
                })}
              </li>
            </ul>
          </div>
          <div className='block m-4'>
            <h3 className='text-4xl text-center'>
              {intl.formatMessage({
                id: 'dao.part3.0.for_partners',
                defaultMessage: 'Для проектов-партнеров:',
              })}
            </h3>
            <ul className='list-disc m-8 text-2xl'>
              <li className='m-5'>
                {intl.formatMessage({
                  id: 'dao.part3.1.active_community',
                  defaultMessage:
                    'Живое и активное коммьюнити амбассадоров-энтузиастов, которое 24/7 в крипте',
                })}
              </li>
              <li className='m-5'>
                {intl.formatMessage({
                  id: 'dao.part3.2.content',
                  defaultMessage:
                    'Целый завод по производству как топ развлекательного, так и образовательного контента',
                })}
              </li>
              <li className='m-5'>
                {intl.formatMessage({
                  id: 'dao.part3.3.brightest_minds',
                  defaultMessage:
                    'Светлейшие умы человечества, которые щелкают любые блокчейн орешки на раз',
                })}
              </li>
              <li className='m-5'>
                {intl.formatMessage({
                  id: 'dao.part3.4.oranges',
                  defaultMessage:
                    'Заводные апельсины, способные раскачать движуху в социальных сетях классного проекта',
                })}
              </li>
            </ul>
          </div>
        </div>

        <div className='flex justify-center pb-6 lg:pb-0'>
          <div className='hidden md:block w-48' />
          <div>
            <p className='text-3xl md:text-5xl text-center'>
              {intl.formatMessage({
                id: 'dao.part4.join',
                defaultMessage: 'Присоединяйся!',
              })}
            </p>
            <div className='flex  text-center mx-auto justify-center'>
              <a
                href={telegramUrl}
                target='_blank'
                rel='noreferrer'
                className='w-32 h-32 mx-1'
              >
                <img src={telegram} alt='' loading='lazy' />
              </a>
              <a
                href={discordUrl}
                target='_blank'
                rel='noreferrer'
                className='w-32 h-32 mx-1'
              >
                <img src={discord} alt='' loading='lazy' />
              </a>
            </div>
          </div>
          <div className='hidden md:block w-48'>
            <img src={delovoy} alt='Delovoy mem' loading='lazy' />
          </div>
        </div>
      </Container>
    </div>
  );
}
