import { useIntl } from 'react-intl';

import Container from 'components/core/container';

import one from 'assets/images/team/1.1.jpg';
import two from 'assets/images/team/1.2.jpg';
import three from 'assets/images/team/1.3.jpg';
import four from 'assets/images/team/1.4.jpg';
import five from 'assets/images/team/1.5.jpg';
import six from 'assets/images/team/1.6.jpg';
import seven from 'assets/images/team/1.7.jpg';
import eight from 'assets/images/team/1.8.jpg';
import nine from 'assets/images/team/1.9.jpg';
import ten from 'assets/images/team/1.10.jpg';
import eleven from 'assets/images/team/1.11.jpg';
import twelve from 'assets/images/team/1.12.jpg';
import thirteen from 'assets/images/team/1.13.jpg';
import fourteen from 'assets/images/team/1.14.jpg';
import fifteen from 'assets/images/team/1.15.jpg';
import sixteen from 'assets/images/team/1.16.jpg';
import seventeen from 'assets/images/team/1.17.jpg';
import eighteen from 'assets/images/team/1.18.jpg';
import nineteen from 'assets/images/team/1.19.jpg';
import twenty from 'assets/images/team/1.20.jpg';

type Team = {
  imageUrl: string;
  name: string;
  job: string;
};

type TeamItemProps = {
  project: Team;
};

function TeamItem(props: TeamItemProps) {
  return (
    <li className='bg-teamCard bg-no-repeat bg-contain p-[35px] min-w-[260px] max-w-[260px]'>
      <img
        src={props.project.imageUrl}
        alt=''
        width={512}
        height={512}
        loading='lazy'
      />

      <div className='flex flex-col items-center justify-center min-h-[60px] text-white text-sm font-bold mt-1'>
        <div>{props.project.name}</div>
        <div>{props.project.job}</div>
      </div>
    </li>
  );
}

type TeamProps = {};

export default function Projects(props: TeamProps) {
  const intl = useIntl();
  const projects: Team[] = [
    {
      imageUrl: one,
      name: 'Rusha',
      job: 'Universal degen',
    },
    {
      imageUrl: two,
      name: 'El Gato',
      job: 'THORChad, AI Arena-maxi',
    },
    {
      imageUrl: three,
      name: 'Ro',
      job: 'THORChad since 0.06$',
    },
    {
      imageUrl: four,
      name: 'Vlad Manz',
      job: 'THORChad since 0.06$',
    },
    {
      imageUrl: five,
      name: 'Ruslan',
      job: 'Universal degen',
    },
    {
      imageUrl: six,
      name: 'Grinksol',
      job: 'Solana-maxi',
    },
    {
      imageUrl: seven,
      name: 'Soltys',
      job: 'Chief Meme Officer',
    },
    {
      imageUrl: eight,
      name: 'Cal_ambur',
      job: 'Crypto Gamers Cult',
    },
    {
      imageUrl: nine,
      name: 'Sanguine',
      job: 'Delovoy',
    },
    {
      imageUrl: ten,
      name: 'dimi',
      job: 'Developer, THORChad',
    },
    {
      imageUrl: eleven,
      name: 'shizuoka',
      job: 'Shizuoka Crypto',
    },
    {
      imageUrl: twelve,
      name: 'Catty',
      job: 'Artist, Delovoy DAO illustrator',
    },
    {
      imageUrl: thirteen,
      name: ' ?',
      job: 'DeFi-analyst',
    },
    {
      imageUrl: fourteen,
      name: ' Andrew',
      job: 'Crypto QA enthusiast ',
    },
    {
      imageUrl: fifteen,
      name: 'Backeromoff',
      job: 'Hegic adept',
    },
    {
      imageUrl: sixteen,
      name: ' Ivan',
      job: 'Hegic adept',
    },
    {
      imageUrl: seventeen,
      name: 'Reptiloyd',
      job: 'Chief Degen Lizard',
    },
    {
      imageUrl: eighteen,
      name: 'IL',
      job: 'THORChad',
    },
    {
      imageUrl: nineteen,
      name: 'justhuman',
      job: 'Voltz-man',
    },
    {
      imageUrl: twenty,
      name: 'moy∆rt',
      job: 'Beginner web3-degen',
    },
  ];

  return (
    <div>
      <h3
        id='team'
        className='bg-gradientLight text-4xl text-center py-5 scroll-mt-[64px]'
      >
        {intl.formatMessage({
          id: 'team.part1.our_team',
          defaultMessage: 'Наша команда',
        })}
      </h3>
      <Container>
        <div className='flex justify-center lg:justify-start'>
          <div className='w-56 h-32 bg-black/90 text-white m-4 flex items-center text-center p-4 rounded-xl'>
            {intl.formatMessage({
              id: 'team.part2.evangelists',
              defaultMessage: 'Не наемники, но web3-евангелисты',
            })}
          </div>
        </div>
        <div className='flex justify-center mt-8 lg:mt-0 mx-12'>
          <ul className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 text-center leading-7'>
            {projects.map((project, ind) => (
              <TeamItem key={ind} project={project} />
            ))}
          </ul>
        </div>

        <p className='text-sm text-center my-8 font-semibold'>
          {intl.formatMessage({
            id: 'team.part4.other_degens',
            defaultMessage: 'И другие web3 дегены',
          })}
        </p>
        <div className='flex justify-center lg:justify-end'>
          <div className='w-56 h-32 bg-black/90 text-white m-4 flex items-center text-center p-4 rounded-xl'>
            {intl.formatMessage({
              id: 'team.part3.content',
              defaultMessage: 'Делаем контент с душой',
            })}
          </div>
        </div>
      </Container>
    </div>
  );
}
