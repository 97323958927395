import NavBar from './navbar';
import Body from './body';
import Footer from './footer';
import MobileMenu from './mobile-menu';
import { useState } from 'react';
import { ProvideBaseStores } from 'providers/BaseStoresProvider';

function App(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ProvideBaseStores>
      <div className='mx-auto font-primary'>
        <div className='flex flex-col justify-center max-w-full'>
          <NavBar onOpenMenu={() => setIsOpen(true)} />
          <Body />
          <Footer />

          <MobileMenu open={isOpen} onCloseMenu={() => setIsOpen(false)} />
        </div>
      </div>
    </ProvideBaseStores>
  );
}

export default App;
