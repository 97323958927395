import { useIntl } from 'react-intl';

type RoadmapProps = {};

export default function Roadmap(props: RoadmapProps) {
  const intl = useIntl();
  return (
    <div className=''>
      <h3
        id='roadmap'
        className='bg-gradientLight text-4xl text-center py-5 scroll-mt-[64px]'
      >
        Roadmap
      </h3>
      <p className='text-2xl text-center leading-loose py-4 px-2'>
        {intl.formatMessage({
          id: 'roadmap.part1.crypto',
          defaultMessage:
            'В крипте все слишком быстро меняется, чтобы строить какие-то долгосрочные планы. ',
        })}
        <br />
        {intl.formatMessage({
          id: 'roadmap.part2.trends',
          defaultMessage:
            'Мы гибкие и всегда ищем новые тренды и подстраиваемся под них.',
        })}
      </p>
    </div>
  );
}
